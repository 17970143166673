import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"

const Package = () => (
  <Layout>
    <SEO
      title="Lifestyle Block Start-Up Package"
      description="New to a lifestyle block? Unsure of what animals fit your needs? Unanswered questions about yards, shelter or troughs? We can help!"
    />
    <section>
      <div className="container">
        <h1 className="section-heading">Lifestyle Block Start-Up Package</h1>
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <p>
                Owning a lifestyle block and making sure you are meeting all of
                your animals requirements can be a tough gig. The team at
                Lakeside Lifestyle can help you to work towards your goals of
                lifestyle animal ownership.
              </p>
              <p>
                Whether you have owned animals for years and just want a little
                more guidance or are new to owning production animals and don’t
                even know how many animals your block of paradise can support,
                let’s chat to see how we can help you!
              </p>
            </div>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 mb-4">
            <StaticImage
              aspectRatio={16 / 10}
              src="../../images/sheep.jpg"
              alt="sheep"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Package
